import { mapEnumToOptions } from '@abp/ng.core';

export enum SettingDefinition {
  SMTPServerSettingGroup = 1,
  SMTPHost = 2,
  SMTPUseSSL = 3,
  SMTPPort = 4,
  SMTPUseAuthentication = 5,
  SMTPCredentialName = 6,
  WaitTimespan = 7,
  EMailAdditionalRecipients = 8,
  EMailFromAddress = 9,
  EMailFromName = 10,
  EMailUseBCC = 11,
  EMailSendToUser = 12,
  EMailTemplateId = 13,
  NameTemplate = 14,
  O365SkuId = 15,
  O365UsageLocation = 16,
  O365MaxConcurrentLicenses = 17,
  Password = 18,
  O365PreferredDataLocationCode = 19,
  UseRandomPassword = 20,
  O365RemoveAllLicenses = 21,
  WaitTime = 22,
  MaxSendSizeBytes = 23,
  MaxReceiveSizeBytes = 24,
  OrganizationalUnit = 25,
  RetentionPolicy = 26,
  UseCustomRootFolder = 27,
  CustomRootFolderName = 28,
  UseSubfolderPerPSTFile = 29,
  SubfolderPerPSTFileNamingConvention = 30,
  MaxFailedItemsPerPST = 31,
  DiscoverPSTTargets = 32,
  PSTDisconnectDelay = 33,
  PSTDisableGrow = 34,
  DisablePST = 35,
  AgentAutoUpdate = 36,
  MaxParallelAgentUpdates = 38,
  PSTUploadPolicy = 39,
  ContinuousPSTScanEnabled = 40,
  PSTScanInterval = 41,
  OwnerUsernameMatchingExtensionAttributes = 42,
  PSTSetOwnerConfirmedToMigrate = 43,
  MinimumAgentWatchdogVersion = 44,
  AutoEnableDiscoveryOnNewAgents = 45,
  AutoEnableDiscoveryOnNewUsers = 46,
  SMTPUseDefaultCredentials = 47,
  SubfolderPerPSTFileNamingConventionCustomTemplate = 48,
  CentralMigrationParallelMailboxesCount = 49,
  CentralMigrationMaxBatchSizeBytes = 50,
  CentralMigrationMaxItemCountPerBatch = 51,
  CentralMigrationParallelBatchCountPerMailbox = 52,
  DiscoverPSTTargetExclusions = 53,
  LocalChainOfCustodyLoggingEnabled = 54,
  DuplicateItemDetectionEnabled = 55,
  MigrateEmptyFolders = 56,
  PSTMigrationOrder = 57,
}

export const settingDefinitionOptions = mapEnumToOptions(SettingDefinition);
