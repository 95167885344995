import { mapEnumToOptions } from '@abp/ng.core';

export enum SettingDefinitionDataType {
  String = 1,
  Long = 2,
  DateTime = 3,
  Bool = 4,
}

export const settingDefinitionDataTypeOptions = mapEnumToOptions(SettingDefinitionDataType);
