import { mapEnumToOptions } from '@abp/ng.core';

export enum SettingDefinitionSection {
  SMTP = 1,
  StepSettings = 2,
  Updates = 3,
  ContinuousPSTScan = 4,
  OwnerMatching = 5,
  CentralMigrationModule = 6,
}

export const settingDefinitionSectionOptions = mapEnumToOptions(SettingDefinitionSection);
